import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from "@angular/core";
import {LoadState} from "core";

@Component({
  selector: "app-modal-wrapper",
  templateUrl: "./modal-wrapper.component.html",
  styleUrls: ["./modal-wrapper.component.scss"]
})
export class ModalWrapperComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() hasClose = true;
  @Input() hasHelp = true;
  @Input() hasFooter = false;
  @Input() hasShortcut = false;
  @Input() isScrollAntiBlockDisabled = false;
  @Input() loadState: LoadState;
  @Output() close = new EventEmitter<any>();
  @Output() help = new EventEmitter<any>();
  @Output() titleClick = new EventEmitter<any>();
  @Output() shortcut = new EventEmitter<any>();

  constructor(private renderer: Renderer2) {
  }

  headerTouchStart($event: TouchEvent) {
    $event.preventDefault();
  }

  ngOnInit() {
    this.renderer.addClass(document.body, "overflow-hidden");
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "overflow-hidden");
  }
}
