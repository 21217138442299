import {environment} from "environment";

export const AppConfig = {
  apiRoutesMap: {
    "health/ping": "api/health/ping",
    "auth/logout": "api/auth/logout",
    "auth/password-reset-token": "api/auth/password-reset-token",
    "auth/reset-password": "api/auth/reset-password",

    "3rd-unauthorized/fiscal-info/{fiscalId}": "api/3rd-unauthorized/fiscal-info/:fiscalId",
    "3rd/fiscal-info": "api/3rd/fiscal-info",
    "3rd/fiscal-info/{fiscalId}": "api/3rd/fiscal-info/:fiscalId",
    "3rd/currency-exchange/{currencyFrom}/{exchangeDate}/{currencyTo}": "api/3rd/currency-exchange/:currencyFrom/:exchangeDate/:currencyTo",
    "3rd/ceccar/{region}": "api/3rd/ceccar/:region",

    "account": "api/account",
    "account/photo": "api/account/photo",
    "account/profile": "api/account/profile",
    "account/password": "api/account/password",
    "account/email": "api/account/email",
    "account/address": "api/account/address",
    "account/events": "api/account/events",
    "account/settings": "api/account/settings",
    "account/invoicing": "api/account/invoicing",
    "account/address-book": "api/account/address-book",

    "shop": "api/shop",
    "shop/items": "api/shop/items",
    "shop/items/{itemId}": "api/shop/items/:itemId",
    "shop/items/{type}/{originId}": "api/shop/items/:type/:originId",
    "shop/basket": "api/shop/basket",
    "shop/order": "api/shop/order",
    "shop/order/payment/confirm": "api/shop/order/payment/confirm",
    "shop/order/deliver": "api/shop/order/deliver",
    "shop/order/{orderId}/status": "shop/order/:orderId/status",

    "shop/publish": "api/shop/publish",

    "purchased/{itemId}": "api/purchased/:itemId",
    "purchased/integrations": "api/purchased/integrations",
    "purchased/integrations/{itemId}": "api/purchased/integrations/:itemId",
    "purchased/integrations/{itemId}/execute": "api/purchased/integrations/:itemId/execute",
    "purchased/workspace-licenses/{workspaceId}": "api/purchased/workspace-licenses/:workspaceId",
    "purchased/workspace-export-definitions/{workspaceId}": "api/purchased/workspace-export-definitions/:workspaceId",
    "purchased/workspace-export-definitions/{workspaceId}/{itemId}": "api/purchased/workspace-export-definitions/:workspaceId/:itemId",
    "purchased/archive-exporters/{workspaceId}": "api/purchased/archive-exporters/:workspaceId",
    "purchased/archive-exporters/{workspaceId}/{itemId}": "api/purchased/archive-exporters/:workspaceId/:itemId",

    "users/{userId}": "api/users/:userId",
    "notifications": "api/notifications",
    "notifications/count": "api/notifications/count",
    "notifications/{key}": "api/notifications/:key",

    "workspaces": "api/workspaces",
    "workspaces/count": "api/workspaces/count",
    "workspaces/email/validate": "api/workspaces/email/validate",
    "workspaces/email/suggest": "api/workspaces/email/suggest",
    "workspaces/{workspaceId}": "api/workspaces/:workspaceId",
    "workspaces/{workspaceId}/photo": "api/workspaces/:workspaceId/photo",
    "workspaces/{workspaceId}/email/validate": "api/workspaces/:workspaceId/email/validate",
    "workspaces/{workspaceId}/email/suggest": "api/workspaces/:workspaceId/email/suggest",

    "workspaces/{workspaceId}/users": "api/workspaces/:workspaceId/users",
    "workspaces/{workspaceId}/users/{workspaceUserId}": "api/workspaces/:workspaceId/users/:workspaceUserId",
    "workspaces/{workspaceId}/users/{workspaceUserId}/status/{memberStatus}": "api/workspaces/:workspaceId/users/:workspaceUserId/status/:memberStatus",
    "workspaces/{workspaceId}/users/invitations": "api/workspaces/:workspaceId/users/invitations",
    "workspaces/{workspaceId}/shortcuts": "api/workspaces/:workspaceId/shortcuts",
    "workspaces/{workspaceId}/shortcuts/{shortcutId}": "api/workspaces/:workspaceId/shortcuts/:shortcutId",
    "workspaces/{workspaceId}/shortcuts/sets": "api/workspaces/:workspaceId/shortcuts/sets",
    "workspaces/{workspaceId}/shortcuts/sets/{setId}": "api/workspaces/:workspaceId/shortcuts/sets/:setId",

    "workspaces/collaborators": "api/workspaces/collaborators",
    "workspaces/collaborators/count": "api/workspaces/collaborators/count",
    "workspaces/invitations": "api/workspaces/invitations",
    "workspaces/invitations/count": "api/workspaces/invitations/count",

    "workspaces/{workspaceId}/document-types": "api/workspaces/:workspaceId/document-types",
    "workspaces/{workspaceId}/document-types/{documentTypeId}": "api/workspaces/:workspaceId/document-types/:documentTypeId",
    "workspaces/{workspaceId}/document-types/{documentTypeId}/dependencies": "api/workspaces/:workspaceId/document-types/:documentTypeId/dependencies",
    "workspaces/{workspaceId}/document-types/import": "api/workspaces/:workspaceId/document-types/import",
    "workspaces/{workspaceId}/document-types/{documentTypeId}/designs": "api/workspaces/:workspaceId/document-types/:documentTypeId/designs",
    "workspaces/{workspaceId}/document-types/{documentTypeId}/designs/{documentDesignId}": "api/workspaces/:workspaceId/document-types/:documentTypeId/designs/:documentDesignId",

    "workspaces/{workspaceId}/backups": "api/workspaces/:workspaceId/backups",
    "workspaces/{workspaceId}/backups/{workspaceBackupId}": "api/workspaces/:workspaceId/backups/:workspaceBackupId",

    "documents": "api/documents",
    "documents/field-memory": "api/documents/field-memory",
    "documents/supported-mime-types": "api/documents/supported-mime-types",
    "documents/supported-file-types": "api/documents/supported-file-types",
    "documents/count": "api/documents/count",
    "documents/from-url": "api/documents/from-url",
    "documents/{documentId}": "api/documents/:documentId",
    "documents/{documentId}/text": "api/documents/:documentId/text",
    "documents/{documentId}/1st-page-image": "api/documents/:documentId/1st-page-image",
    "documents/{documentId}/events": "api/documents/:documentId/events",
    "documents/{documentId}/join-sources": "api/documents/:documentId/join-sources",
    "documents/{documentId}/extract-data": "api/documents/:documentId/extract-data",
    "documents/{documentId}/split": "api/documents/:documentId/split",
    "documents/{documentId}/name": "api/documents/:documentId/name",
    "documents/{documentId}/source": "api/documents/:documentId/source",
    "documents/{documentId}/source/from-url": "api/documents/:documentId/source/from-url",
    "documents/{documentId}/renderings": "api/documents/:documentId/renderings",
    "documents/{documentId}/renderings/{documentRenderingId}": "api/documents/:documentId/renderings/:documentRenderingId",
    "documents/{documentId}/renderings/{documentRenderingId}/email/send": "api/documents/:documentId/renderings/:documentRenderingId/email/send",
    "documents/{documentId}/renderings/{documentRenderingId}/email/preview": "api/documents/:documentId/renderings/:documentRenderingId/email/preview",
    "documents/{documentId}/renderings/{documentRenderingId}/email/recipient": "api/documents/:documentId/renderings/:documentRenderingId/email/recipient",
    "documents/change": "api/documents/change",
    "documents/unarchive": "api/documents/unarchive",
    "documents/join": "api/documents/join",
    "documents/registry": "api/documents/registry",
    "documents/search": "api/documents/search",
    "documents/{box}/{workspaceId}": "api/documents/:box/:workspaceId",
    "documents/{workspaceId}/count": "api/documents/:workspaceId/count",
    "documents/{workspaceId}/document-type/{documentTypeId}": "api/documents/:workspaceId/document-type/:documentTypeId",
    "documents/{box}/{workspaceId}/export/{exporterId}": "api/documents/:box/:workspaceId/export/:exporterId",
    "documents/{box}/{workspaceId}/export/{exporterId}/stats": "api/documents/:box/:workspaceId/export/:exporterId/stats",
    "documents/{box}/{workspaceId}/totals": "api/documents/:box/:workspaceId/totals",
    "documents/{box}/{workspaceId}/document-types": "api/documents/:box/:workspaceId/document-types",

    "payments": "api/payments",
    "credits": "api/credits",

    "files": "api/files/:path",
    "files/presigned": "api/files/presigned",
    "files/preview": "api/files/preview",

    "public/renderings/{renderingGuid}": "api/public/renderings/:renderingGuid",

    "bof/invoice-design": "api/bof/invoice-design",
    "bof/app-users": "api/bof/app-users",
    "bof/app-users/{userId}": "api/bof/app-users/:userId",
    "bof/app-users/credits": "api/bof/app-users/credits",
    "bof/shop-orders": "api/bof/shop-orders",
    "bof/shop-orders/{orderId}": "api/bof/shop-orders/:orderId",
    "bof/shop-items": "api/bof/shop-items",
    "bof/companies": "api/bof/companies",

    "logs/{key}": "api/logs/:key",

    "tours/{tourId}/status": "api/tours/:tourId/status"
  },

  authConfig: {
    loginUrl: environment.baseUrl + "api/auth/login",
    signupUrl: environment.baseUrl + "api/register",
    tokenName: "token",
    tokenPrefix: "actas",
    authToken: "Bearer",
    providers: {
      facebook: {
        url: environment.baseUrl + "api/auth/facebook",
        clientId: environment.facebookClientId
      },
      google: {
        url: environment.baseUrl + "api/auth/google",
        clientId: environment.googleClientId,
        scope: ["profile", "email"]
      }
    }
  },

  languages: [{
    id: "ro", name: "Romana"
  }, {
    id: "en", name: "English"
  }],

  l10n: {
    ro: {
      date: "DD.MM.YYYY",
      dateTime: "DD.MM.YYYY HH:mm"
    }, en: {
      date: "YYYY.MM.DD",
      dateTime: "YYYY.MM.DD HH:mm"
    }
  }
};
