import {Directive, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit} from "@angular/core";
import {Meta} from "@angular/platform-browser";
import {DOCUMENT} from "@angular/common";
import {Platform} from "@ionic/angular";
import {ClipboardService} from "ngx-clipboard";
import {EMPTY, Observable, Subscriber, Subscription} from "rxjs";
import {ShareData, ShareMethod, ShareType, ShareTypes} from "./simple-share.models";

@Directive({
  selector: "[appSimpleShare]"
})
export class SimpleShareDirective implements OnInit, OnDestroy {
  @Input() appSimpleShare: string;
  @Input() shareData?: ShareData;

  private shareType: ShareType;
  private sub: Subscription;

  constructor(private el: ElementRef,
              private meta: Meta,
              private platform: Platform,
              private clipboardService: ClipboardService,
              @Inject(DOCUMENT) private document: any) {
  }

  @HostListener("click")
  click() {
    this.shareType = ShareTypes[this.appSimpleShare];
    if (!this.shareType) {
      return console.error(`Share type ${this.appSimpleShare} is undefined`);
    }
    this.sub = this.share()
      .subscribe(() => console.log("copied"));
  }

  ngOnInit() {
    this.shareData = this.shareData || this.getShareDataFromMeta();
  }

  ngOnDestroy() {
    if (!this.sub) {
      return;
    }
    this.sub.unsubscribe();
  }

  private share(): Observable<void> {
    let shareLink: string | (() => Observable<any>);
    if (this.platform.is("ios") && this.shareType.shareUrl.ios) {
      shareLink = this.shareType.shareUrl.ios;
    } else if (this.platform.is("android") && this.shareType.shareUrl.android) {
      shareLink = this.shareType.shareUrl.ios;
    } else {
      shareLink = this.shareType.shareUrl.desktop;
    }

    if (typeof shareLink === "function") {
      return shareLink.apply(this, [this.clipboardService, this.shareData]);
    }

    const serializedParams = Object.entries(this.shareType.shareParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(this.evaluateParam(value))}`)
      .join("&");

    shareLink += serializedParams;

    if (this.shareType.method === ShareMethod.Link) {
      const linkElement: HTMLLinkElement = this.document.createElement("a");
      linkElement.setAttribute("target", this.shareType.target || "_blank");

      // Prevent security vulnerability https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
      linkElement.setAttribute("rel", "noopener noreferrer");
      linkElement.href = shareLink;
      linkElement.click();
      linkElement.remove();
    } else if (this.shareType.method === ShareMethod.Window) {
      const popUpWindow = window.open(shareLink, this.shareType.target || "_blank", "width: 800, height: 400");
      // Prevent security vulnerability https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
      window.opener = null;
      if (popUpWindow) {
        return new Observable<void>((sub: Subscriber<void>) => {
          const pollTimer = this.document.defaultView.setInterval(() => {
            if (popUpWindow.closed) {
              this.document.defaultView.clearInterval(pollTimer);
              // this.closed.emit(this.shareButtonName);
              sub.next();
              sub.complete();
            }
          }, 200);
        });
      }
    }
    return EMPTY;
  }

  private evaluateParam(param: string): string {
    let ret = param;
    Object.keys(this.shareData).forEach((k: string) => {
      ret = ret.replace(new RegExp(`{{${k}}}`, "g"), this.shareData[k]);
    });
    return ret.trim();
  }

  private getShareDataFromMeta(): ShareData {
    return {
      title: this.getMetaTagContent("og:title"),
      body: this.getMetaTagContent("og:description"),
      image: this.getMetaTagContent("og:image"),
      url: window.location.href
    };
  }

  private getMetaTagContent(key: string): string {
    const metaUsingProperty: HTMLMetaElement = this.meta.getTag(`property="${key}"`);
    if (metaUsingProperty) {
      return metaUsingProperty.getAttribute("content");
    }
    const metaUsingName: HTMLMetaElement = this.meta.getTag(`name="${key}"`);
    if (metaUsingName) {
      return metaUsingName.getAttribute("content");
    }
    return "";
  }
}
