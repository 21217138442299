import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {MqEvent, MqService} from "core";
import {Document} from "./documents.model";

@Injectable({providedIn: "root"})
export class DocumentsMqService {

  constructor(private mqService: MqService) {
  }

  notifyDocumentCreated(document: Document, fromSocket: boolean) {
    console.log("pub", document, fromSocket);
    this.mqService.pub("document.created", {payload: document, fromSocket});
  }

  onDocumentCreated(evtFilter: {documentId?: number} = null): Observable<MqEvent<Document>> {
    let obs = this.mqService.evt("document.created");
    if (evtFilter && evtFilter.documentId) {
      obs = obs.pipe(filter(x => x.payload.id === +evtFilter.documentId));
    }
    return obs.pipe(tap((x) => {
      console.log("onDocumentCreated", x);
    }));
  }

  notifyDocumentUpdated(document: Document, fromSocket: boolean) {
    this.mqService.pub("document.updated", {payload: document, fromSocket});
  }

  onDocumentUpdated(evtFilter: {documentId?: number} = null): Observable<MqEvent<Document>> {
    let obs = this.mqService.evt("document.updated");
    if (evtFilter && evtFilter.documentId) {
      obs = obs.pipe(filter(x => x.payload.id === +evtFilter.documentId));
    }
    return obs.pipe(tap((x) => {
      console.log("onDocumentUpdated", x);
    }));
  }

  notifyDocumentDeleted(document: Document, fromSocket: boolean) {
    this.mqService.pub("document.deleted", {payload: document, fromSocket});
  }

  onDocumentDeleted(evtFilter: {documentId?: number} = null): Observable<MqEvent<Document>> {
    let obs = this.mqService.evt("document.deleted");
    if (evtFilter && evtFilter.documentId) {
      obs = obs.pipe(filter(x => x.payload.id === +evtFilter.documentId));
    }
    return obs.pipe(tap((x) => {
      console.log("onDocumentDeleted", x);
    }));
  }

  notifyTotalDocumentsCount(count: {inRegistry: number, inWorkspaces: number, archived: number}) {
    this.mqService.pub("total.documents.count", count);
  }

  onTotalDocumentsCount(): Observable<{inRegistry: number, inWorkspaces: number, archived: number}> {
    return this.mqService.evt("total.documents.count");
  }

}
