<ng-container [formGroup]="form">
  <ng-container *ngIf="fieldDataType === FieldDataType.ImageUrl">
    <div class="ion-card-subtitle mt-4" translate="Image dimensions"></div>
    <div class="d-flex align-items-center">
      <ion-item class="floating-label">
        <ion-input formControlName="fieldWidth"
                   label="W"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
      <div class="px-2">x</div>
      <ion-item class="floating-label">
        <ion-input formControlName="fieldHeight"
                   label="H"
                   labelPlacement="floating">
        </ion-input>
      </ion-item>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldDataType === FieldDataType.Numeric">
    <div class="ion-card-subtitle mt-4" translate="Format"></div>
    <div class="d-flex align-items-center">
      <ion-segment formControlName="fieldFormatNumericThousandsSeparator">
        <ng-container *ngFor="let x of ['', '.', ',', 'abc']">
          <ion-segment-button [value]="x" layout="icon-start">
            {{fieldFormatNumeric(x)}}
          </ion-segment-button>
        </ng-container>
      </ion-segment>
    </div>

    <div class="ion-card-subtitle mt-4" translate="Decimals"></div>
    <div class="d-flex align-items-center">
      <ion-segment formControlName="fieldFormatNumericDecimals">
        <ng-container *ngFor="let x of [0, 1, 2, 3, 4]">
          <ion-segment-button [value]="x" layout="icon-start">
            .{{x}}
          </ion-segment-button>
        </ng-container>
      </ion-segment>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldDataType === FieldDataType.Date">
    <div class="ion-card-subtitle mt-4" translate="Format"></div>
    <div class="d-flex align-items-center">
      <ion-segment formControlName="fieldFormatDate">
        <ng-container *ngFor="let x of ['dd.MM.yyyy', 'yyyy-MM-dd']">
          <ion-segment-button [value]="x" layout="icon-start">
            {{x}}
          </ion-segment-button>
        </ng-container>
      </ion-segment>
    </div>
  </ng-container>

  <ng-container *ngIf="fieldDataType === FieldDataType.Boolean">
    <div class="ion-card-subtitle mt-4" translate="Format"></div>
    <div class="d-flex align-items-center">
      <ion-segment formControlName="fieldFormatBoolean">
        <ng-container *ngFor="let x of ['', 'True/False', 'Yes/No', 'Da/Nu', 'X', '*', '+', '✔︎']">
          <ion-segment-button [value]="x" layout="icon-start">
            {{x}}
          </ion-segment-button>
        </ng-container>
      </ion-segment>
    </div>
  </ng-container>

  <div class="ion-card-subtitle mt-4" translate="Filter"></div>
  <div class="row no-gutters">
    <ion-item appIonFloatingLabel
              class="col-6 pl-1">
      <ion-label translate="exporterFilterRule.operation.6"></ion-label>
      <select class="m-0 p-0 border-0 w-100"
              formControlName="fieldTransformCase">
        <option *ngFor="let x of casingTypes" [value]="x">{{x}}</option>
      </select>
    </ion-item>
    <ion-item appIonFloatingLabel
              class="col-6 pl-1">
      <ion-label translate="exporterFilterRule.operation.5"></ion-label>
      <select class="m-0 p-0 border-0 w-100"
              formControlName="fieldTransformTrim">
        <option *ngFor="let x of trimTypes" [value]="x">{{x}}</option>
      </select>
    </ion-item>
  </div>

</ng-container>
