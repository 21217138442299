import {Injectable} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {ApiService} from "../api/api.service";

export interface FiscalInfoApiData {
  id: number;
  cui: string;
  name: string;
  companyInfo: {
    j: string;
    region: string;
    city: string;
    address: string;
    zipCode: string;
    phone: string;
    email: string;
    website: string;
    caen: string;
    employee: string;
    jointStock: string;
    iban: string;
    bank: string;
    swift: string;
    legalRepresentative: {
      name: string;
      email: string;
      phone: string;
    };
  };
}

@Injectable({providedIn: "root"})
export class FiscalInfoApiService {
  constructor(private apiService: ApiService) {
  }

  searchFiscalData(search: string): Observable<Array<FiscalInfoApiData>> {
    if (!search) {
      return of([null]);
    }
    const qry = ApiService.buildQuery([{
      search,
    }]);
    return this.apiService.get(`3rd/fiscal-info?${qry}`, {});
  }

  readFiscalData(fiscalId: string, apiKey: string = null): Observable<FiscalInfoApiData> {
    if (!fiscalId) {
      return of(null);
    }
    const qry = ApiService.buildQuery([{
      apiKey: apiKey,
    }]);

    return apiKey
      ? this.apiService.get(`3rd-unauthorized/fiscal-info/{fiscalId}?${qry}`, {fiscalId})
      : this.apiService.get(`3rd/fiscal-info/{fiscalId}`, {fiscalId});
  }

  updateFiscalData(fiscalId: string, payload: FiscalInfoApiData): Observable<FiscalInfoApiData> {
    return this.apiService.put("3rd/fiscal-info/{fiscalId}", {fiscalId}, payload);
  }
}
