import {Component, Input, OnInit, OnDestroy, Type} from "@angular/core";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {ModalController} from "@ionic/angular";
import {Observable, Subscription} from "rxjs";
import {ErrorString, HotkeyEvent, HotkeyMap, HotkeysService, LoadState, SubSink, TourExtService, TranslateInterpolation} from "core";
import {ActionBarItem, ActionBarType} from "shared";

@Component({
  selector: "app-modal-any",
  templateUrl: "./modal-any.component.html",
  styleUrls: ["./modal-any.component.scss"]
})
export class ModalAnyComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() tourId: string;
  @Input() hasClose = true;
  @Input() itemComponent: Type<any>;
  @Input() itemProps: {[key: string]: any};
  @Input() itemValue: any;
  @Input() submitCaption: string;
  @Input() submitProps: {
    buttonType: "normal" | "fab"
    icon: string;
    color: string;
  };
  @Input() actions: Array<ActionBarItem>;
  @Input() onChanged: Function;
  @Input() onCreated: (T: any) => void;
  @Input() submit$: () => Observable<any>;
  @Input() onShortcutRequest: () => void;
  @Input() keyboardShortcuts: Array<HotkeyMap>;
  @Input() onHotKeyPressed: (e: HotkeyEvent) => void;

  sub: Subscription;
  itemInstance: Component | any;
  loadState = new LoadState();
  ActionBarType = ActionBarType;
  private subSink = new SubSink();
  dynamicInputs: {[key: string]: any};

  constructor(private modalController: ModalController,
              private translateInterpolation: TranslateInterpolation,
              private tourExtService: TourExtService,
              private hotKeysService: HotkeysService) {
  }

  ngOnInit() {
    this.dynamicInputs = this.itemProps || {};
    this.dynamicInputs["created"] = this.created.bind(this);
  }

  ngOnDestroy() {
    console.log("ngOnDestroy ModalAnyComponent");
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.subSink.unsubscribe();
    this.tourExtService.destroy();
  }

  private created(itemInstance: Component | any) {
    setTimeout(() => {
      console.log("created", itemInstance);
      this.tourExtService.init(this.tourId);
      this.itemInstance = itemInstance;
      this.loadState = itemInstance?.loadState?.setInit
        ? itemInstance?.loadState
        : this.loadState;
      this.loadState?.setOk();
      if (this.onCreated) {
        this.onCreated.apply(null, [this.itemInstance]);
      }
      setTimeout(() => {
        if (JSON.stringify(this.itemValue) === "{}" && !itemInstance.writeValue) {
          itemInstance.writeValue = () => {
          };
          itemInstance.formControl = new FormControl(this.itemValue);
        }
        if (itemInstance.writeValue) {
          if (this.itemValue) {
            if (this.itemValue instanceof Observable) {
              this.loadState?.setInit();
              this.itemValue.subscribe((x: any) => {
                itemInstance.writeValue(x);
                this.loadState?.setOk();
              }, async (error) => {
                this.loadState?.setError(error);
              });
            } else {
              itemInstance.writeValue(this.itemValue instanceof Array
                ? this.itemValue
                : typeof this.itemValue === "object"
                  ? Object.assign({}, this.form.value, this.itemValue)
                  : this.itemValue);
            }
          }
          if (this.onChanged || this.actions) {
            this.sub = this.form.valueChanges.subscribe((formValue: any) => {
              if (this.onChanged) {
                this.onChanged.apply(null, [formValue, this]);
              }
              if (this.actions) {
                this.actions.forEach((a: ActionBarItem) => {
                  a.actionValue = formValue;
                  if (a.actions) {
                    a.actions.forEach((y: ActionBarItem) => y.actionValue = formValue);
                  }
                });
              }
            });
          }
        }
      });
      this.hotKeysService.addShortcuts(this.keyboardShortcuts || [])
        .subscribe(x => this.subSink.sink = x.subscribe((e: HotkeyEvent) => {
          this.onHotKeyPressed.apply(null, [e]);
        }));
    }, 100);
  }

  async dismiss() {
    await this.modalController.dismiss();
  }

  handleHelp() {
    this.tourExtService.restart(this.tourId);
  }

  async submit() {
    if (this.submit$) {
      this.loadState?.setWorking();
      this.submit$.apply(null, [this.form.value])
        .subscribe(async (x: any) => {
            this.loadState?.setOk();
            this.form.setErrors(null);
            await this.modalController.dismiss(x);
          },
          async (err) => {
            const errMessage = this.translateInterpolation.translate(new ErrorString().transform(err));
            this.form.setErrors({error: {message: errMessage}});
            this.loadState?.setError(err);
          });
    } else {
      await this.modalController.dismiss(this.form.value);
    }
  }

  get form(): FormGroup | FormControl | FormArray {
    return this.itemInstance?.form || this.itemInstance?.formControl || this.itemInstance?.formArray;
  }
}
