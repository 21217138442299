export enum EventTypes {
  DocumentCreate = "document-create",
  DocumentUpdate = "document-update",
  DocumentDelete = "document-delete",
  DocumentExportOverdue = "document-export-overdue",
  DocumentExportFailed = "document-export-failed",
  WorkspaceUserUpdate = "workspace-user-update",
  WorkspaceUserDelete = "workspace-user-delete",
  WorkspaceBackupCreate = "workspace-backup-create",
  WorkspaceBackupDelete = "workspace-backup-delete",
  WorkspaceDelete = "workspace-delete",
  IntegrationRun = "integration-run"
}

export interface SocketEvent<T> {
  key: string;
  senderUserId: string;
  recipientUserId: string;
  timestamp: Date;
  eventType: EventTypes;
  payload: T;
}
