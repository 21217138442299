import {Injectable} from "@angular/core";
import {Observable, tap} from "rxjs";
import {filter, mergeMap} from "rxjs/operators";
import {IdentityService, IdentityUser} from "../auth/identity.service";
import {SocketEvent} from "./socket-event.models";
import {WebsocketService, WebsocketServiceFactory} from "./websocket.service";
import {environment} from "environment";

@Injectable({providedIn: "root"})
export class LiveService {
  private ws: WebsocketService;

  constructor(private wsService: WebsocketServiceFactory,
              private identityService: IdentityService) {
    this.ws = this.wsService.create(environment.wsUrl);
  }

  emit(event: string, msg: SocketEvent<any>) {
    this.identityService.getUser().subscribe((user: IdentityUser) => {
      this.ws.emit(event, Object.assign(msg, {senderUserId: user.id}));
    });
  }

  event(event: string): Observable<SocketEvent<any>> {
    return this.identityService.getUser().pipe(mergeMap((user: IdentityUser) =>
      this.ws.event(event)
        .pipe(tap((se: SocketEvent<any>) => {
          console.log("live-evt", se);
        }))
        .pipe(filter((se: SocketEvent<any>) => se.recipientUserId === user.id))
        .pipe(tap(x => {
          console.log("live-evt (mine)", x);
        }))
    ));
  }

  isConnected(): boolean {
    return this.ws.isConnected();
  }
}
