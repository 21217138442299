import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy, OnInit,
  Output,
  SimpleChanges, ViewChild
} from "@angular/core";
import {Observable, of, Subject, throwError} from "rxjs";
import {catchError, filter, tap} from "rxjs/operators";
import {IonInput, Platform} from "@ionic/angular";
import {
  ArrayService,
  HateoasList, KeypressUtilsService,
  LoadState, MqEvent,
  ObservableFilteringService,
  Pagination,
  SubSink,
  HotkeysService, HotkeyEvent
} from "core";
import {ActionBarItem} from "shared/action-bar/action.model";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

export type Direction = "left" | "right" | "down" | "up";

@Component({
  selector: "app-items-list",
  templateUrl: "./items-list.component.html",
  styleUrls: ["./items-list.component.scss"]
})
export class ItemsListComponent<T, TF> implements OnChanges, OnInit, OnDestroy {
  @Input() created: (x: ItemsListComponent<T, TF>) => void;
  @Input() loadState = new LoadState();

  @Input() set loader$(value: () => Observable<HateoasList<T>>) {
    this._loader$ = value;
  }

  @Input() initialData: HateoasList<T>;
  @Input() deleter$: (x: T) => Observable<any>;
  @Input() onItemAdd$: Observable<MqEvent<T>>;
  @Input() onItemUpdate$: Observable<MqEvent<T>>;
  @Input() onItemUpsert$: Observable<MqEvent<T>>;
  @Input() onItemDelete$: Observable<MqEvent<T>>;
  @Input() forceRefreshOnSocket: boolean;
  @Input() itemComponent: Component | any;
  @Input() itemInputs: {[key: string]: any};
  @Input() filterService: ObservableFilteringService<TF>;
  @Input() pagination = new Pagination();
  @Input() viewType: "grid" | "list" = "grid";
  @Input() countCaption: string;
  @Input() allowSelection = false;
  @Input() itemHeight: number;
  @Input() allowContentFilter: boolean;
  @Input() contentFilterFn: (item: T, txt: string) => boolean;

  @Output() dataLoad = new EventEmitter();
  @Output() itemClick = new EventEmitter<T>();
  @Output() itemDelete = new EventEmitter<T>();
  @Output() itemSelect = new EventEmitter<{item: T, selectedItems: Array<number>}>();
  @Output() itemDisable = new EventEmitter<{item: T, disabledItems: Array<number>}>();
  @Output() itemAction = new EventEmitter<{$event: any, item: T, action: string, data?: any}>();
  @Output() notifyNeedsReload = new EventEmitter();

  @ViewChild("filterInput") private filterInput: IonInput;
  @ViewChild(CdkVirtualScrollViewport, {static: false}) private cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  private _loader$: () => Observable<HateoasList<T>>;
  private isInitialized = false;
  private sub = new SubSink();
  private selectedItemsIds: Array<number> = [];
  private disabledItemsIds: Array<number> = [];
  private isSelectionMode = false;
  private listWidth: number;
  private contentFilterString: string;

  items: Array<T> = [];
  cols = 0;
  splitOnFn: (x: T) => string;
  rows: Array<Array<T> | string> = [];
  shouldRender = false;
  needsReload: boolean;
  dynamicInputs: {[key: string]: any};

  // dynamicOutputs = {
  //   itemClick: {
  //     handler: (ev: {$event: any, item: T}) => this.onItemClick(ev.$event, ev.item), args: ["$event"]
  //   },
  //   itemDelete: {
  //     handler: (item: T) => {
  //       if (this.deleter$) {
  //         this.deleter$.apply(this, [item]).subscribe(() => {
  //           this.removeItemFromList(item);
  //           this.itemDelete.emit(item);
  //         });
  //       } else {
  //         this.itemDelete.emit(item);
  //       }
  //     }, args: ["$event"]
  //   },
  //   itemPress: {
  //     handler: (ev: {$event: any, item: T}) => this.onItemPress(ev.$event, ev.item), args: ["$event"]
  //   },
  //   itemAction: {
  //     handler: (payload: {item: T, action: string, data: any}) => this.itemAction.emit(payload), args: ["$event"]
  //   }
  // };

  constructor(private platform: Platform,
              private keypressUtilsService: KeypressUtilsService,
              private arrayService: ArrayService,
              private hotKeysService: HotkeysService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.isInitialized || changes.initialData || changes.filterService || changes.pagination) {
      this.init();
      this.hotKeysService.addShortcuts([
        {hotKey: "meta.a", description: "list.selectAll"},
        {hotKey: "esc", description: "list.clearSelection"},
        {hotKey: "arrowLeft", description: "list.nav"},
        {hotKey: "arrowRight", description: "list.nav"},
        {hotKey: "arrowUp", description: "list.nav"},
        {hotKey: "arrowDown", description: "list.nav"},
        {hotKey: "enter", description: "list.open"},
        {hotKey: "space", description: "list.open"}])
        .subscribe(x => this.sub.sink = x.subscribe(this.handleHotkey.bind(this)));
    }
  }

  ngOnInit() {
    console.log("ngOnInit ItemList");
    this.dynamicInputs = this.itemInputs || {};
    this.dynamicInputs["itemActionX"] = (x: {$event: any, item: T, action: string, data?: any}) => {
      if (x.action === "click") {
        this.onItemClick(x.$event, x.item);
      } else if (x.action === "press") {
        this.onItemPress(x.$event, x.item);
      } else if (x.action === "delete") {
        if (this.deleter$) {
          this.deleter$.apply(this, [x.item]).subscribe(() => {
            this.removeItemFromList(x.item);
            this.itemDelete.emit(x.item);
          });
        } else {
          this.itemDelete.emit(x.item);
        }
      } else {
        this.itemAction.emit(x);
      }
    };

    if (this.created) {
      this.created(this);
    }
  }

  ngOnDestroy() {
    // console.log("ngOnDestroy ItemList");
    this.sub.unsubscribe();
  }

  computeRows(forced: boolean = false) {
    if (!this.listWidth && !forced) {
      return;
    }
    const cols = this.viewType === "list"
      ? 1
      : this.listWidth > 992
        ? 4
        : this.listWidth > 768
          ? 3
          : this.listWidth > 576
            ? 2
            : 1;
    if (cols === this.cols && !forced) {
      return;
    }
    console.log("computing");
    this.cols = cols;
    this.rows = [];
    let col = this.cols;
    let prevSplitOn: string = null;
    ((this.contentFilterFn && this.contentFilterString)
      ? this.items.filter(d => this.contentFilterFn.apply(null, [d, this.contentFilterString]))
      : this.items)
      .forEach((x: T) => {
        const curSplitOn = (this.splitOnFn && this.splitOnFn.apply(null, [x])) || null;
        if (curSplitOn !== prevSplitOn) {
          this.rows.push(curSplitOn);
          prevSplitOn = curSplitOn;
          col = this.cols;
        }
        if (col === this.cols) {
          this.rows.push([]);
          col = 0;
        }
        (this.rows[this.rows.length - 1] as Array<T>).push(x);
        col++;
      });
  }

  private init() {
    this.isInitialized = true;
    let currentFilterString: string;
    this.sub.unsubscribe(true);
    const x$ = this.filterService && !this.filterService.isInitialized()
      ? this.filterService.init()
      : of(this.filterService?.filter);

    this.sub.sink = x$.subscribe((iniFilter: TF) => {
      currentFilterString = JSON.stringify(iniFilter);
      // console.log("1st refresh", iniFilter);
      this.pagination.reset();
      this.clearSelection();
      this.disabledItemsIds.length = 0;
      this.loadData().subscribe(() => {
        // console.log("1st refresh done");
        if (this.filterService) {
          this.sub.sink = this.filterService.observe()
            .pipe(filter((f: TF) => currentFilterString !== JSON.stringify(f)))
            .subscribe((f: TF) => {
              currentFilterString = JSON.stringify(f);
              // console.log("filter changed", f);
              this.pagination.reset();
              this.loadData().subscribe();
            });
        }
      });
    });

    if (this.onItemAdd$) {
      this.sub.sink = this.onItemAdd$.subscribe(this.handleOnItemAdd.bind(this));
    }
    if (this.onItemUpdate$) {
      this.sub.sink = this.onItemUpdate$.subscribe(this.handleOnItemUpdate.bind(this));
    }
    if (this.onItemUpsert$) {
      this.sub.sink = this.onItemUpsert$.subscribe(this.handleOnItemUpsert.bind(this));
    }
    if (this.onItemDelete$) {
      this.sub.sink = this.onItemDelete$.subscribe(this.handleOnItemDelete.bind(this));
    }
  }

  loadDataForced($loader: Observable<HateoasList<T>>): Observable<any> {
    this.loadState.setLoading(this.pagination.page === 1);
    return $loader
      .pipe(tap((response: HateoasList<T>) => {
        this.initialData = null;
        if (this.pagination.page === 1) {
          this.items = response.items;
        } else {
          this.items = this.items.concat(response.items);
        }
        this.splitOnFn = this.filterService?.getSplitOnFn();
        this.computeRows(true);
        this.pagination.recordCount = response.totalCount;
        this.dataLoad.emit();
        this.loadState.setOk();
        // setTimeout(() => {
        this.shouldRender = true;
        // }, 10);
      }))
      .pipe(catchError(error => {
        this.loadState.setError(error);
        return throwError(() => error);
      }));
  }

  loadData(): Observable<any> {
    // console.log("loading data...", this.filterService?.filter);
    const ll$ = this.initialData
      ? of(this.initialData).pipe(tap(() => this.rows.length = 0))
      : this._loader$.apply(this, [this.filterService?.filter, this.pagination]);
    return this.loadDataForced(ll$);
  }

  loadMore(): Observable<boolean> {
    if (this.pagination.recordCount === this.items.length || this.needsReload) {
      return of(true);
    }
    this.pagination.increment();
    // console.log("loading more, page: ", this.pagination.page);
    return this.loadData().pipe(tap(() => this.pagination.recordCount === this.items.length));
  }

  refresh(): Observable<any> {
    const s = new Subject<boolean>();
    this.disabledItemsIds.length = 0;
    this.needsReload = false;
    this.pagination.reset();
    this.loadData().subscribe(() => {
      s.next(null);
    });
    return s;
  }

  rowTrackBy(index: number, row: Array<T> | string) {
    return row
      ? typeof row === "string"
        ? row
        : (row[0] as T)["id"]
      : "-";
  }

  hasSelection(): boolean {
    return !!this?.selectedItemsIds?.length;
  }

  getSelectedItemsIds(): Array<number> {
    return this.selectedItemsIds;
  }

  getSelectedItems(): Array<T> {
    return this.items.filter((x: T) => this.selectedItemsIds.indexOf(x["id"]) !== -1);
  }

  getDisabledItemsIds(): Array<number> {
    return this.disabledItemsIds;
  }

  getDisabledItems(): Array<T> {
    return this.items.filter((x: T) => this.disabledItemsIds.indexOf(x["id"]) !== -1);
  }

  getActiveCount(): number {
    return this.selectedItemsIds?.length
      ? this.selectedItemsIds?.length
      : this.pagination?.recordCount || 0;
  }

  sortBy(actionBarItem: ActionBarItem) {
    this.pagination.reset();
    this.filterService.sortBy(actionBarItem.actionValue).subscribe();
  }

  filterBySearch(actionBarItem: ActionBarItem, search: string) {
    this.pagination.reset();
    this.filterService.filterBySearch(search).subscribe();
  }

  getNearSibling(item: T, direction: Direction): T {
    const idx = this.getItemIdx(item);
    if (idx === -1) {
      return null;
    }
    return this.getNearSiblingByIdx(idx, direction);
  }

  getNearSiblingByIdx(idx: number, direction: Direction): T {
    const matrixPos = this.getItemMatrixPosByIdx(idx);
    const id = direction === "left"
      ? (this.items[idx - 1] as any)?.id
      : direction === "right"
        ? (this.items[idx + 1] as any)?.id
        : direction === "down"
          ? (() => {
            let row = null;
            for (let r = matrixPos.rowIdx + 1; r < this.rows.length; r++) {
              row = this.rows[r];
              if (typeof row !== "string") {
                break;
              }
            }
            if (!row) {
              return -1;
            }
            for (let i = matrixPos.colIdx; i >= 0; i--) {
              if (row[i]) {
                return (row[i] as any)?.id;
              }
            }
            return -1;
          })()
          : direction === "up"
            ? (() => {
              let row = null;
              for (let r = matrixPos.rowIdx - 1; r > 0; r--) {
                row = this.rows[r];
                if (typeof row !== "string") {
                  break;
                }
              }
              if (!row) {
                return -1;
              }
              for (let i = matrixPos.colIdx; i >= 0; i--) {
                if (row[i]) {
                  return (row[i] as any)?.id;
                }
              }
              return -1;
            })()
            : -1;
    return this.arrayService.getItem(this.items, x => x.id === id) || null;
  }

  getItemIdx(item: T): number {
    if (!item) {
      return -1;
    }
    const predicate = item["uiId"]
      ? (d: any) => d.uiId === item["uiId"]
      : (d: any) => d.id === item["id"];
    return this.items.findIndex(predicate);
  }

  getItemById(id: any): T {
    return this.items.find((x: T) => (x as any)?.id === id);
  }

  private getItemMatrixPosById(id: any): {rowIdx: number, colIdx: number} {
    const rowIdx = this.rows.findIndex((r: Array<T> | string) =>
      typeof r !== "string" && r.findIndex((x: T) => (x as any)?.id === id) !== -1);
    const row = this.rows[rowIdx] as Array<T>;
    const colIdx = rowIdx === -1
      ? -1
      : row.findIndex((x: T) => (x as any)?.id === id);
    return {rowIdx, colIdx};
  }

  private getItemMatrixPosByIdx(idx: any): {rowIdx: number, colIdx: number} {
    return this.getItemMatrixPosById(this.items[idx]["id"]);
  }

  private isItemFromList(item: T) {
    return this.getItemIdx(item) !== -1;
  }

  selectItem(item: T): boolean {
    if (!this.isItemFromList(item)) {
      return false;
    }

    const idx = this.selectedItemsIds.indexOf(item["id"]);
    if (idx === -1) {
      this.selectedItemsIds.push(item["id"]);
      this.itemSelect.emit({item, selectedItems: this.selectedItemsIds});
      return true;
    }
    return false;
  }

  private unselectItem(item: T): boolean {
    if (!this.isItemFromList(item)) {
      return false;
    }
    const idx = this.selectedItemsIds.indexOf(item["id"]);
    if (idx !== -1) {
      this.selectedItemsIds.splice(idx, 1);
      this.itemSelect.emit({item, selectedItems: this.selectedItemsIds});
      return true;
    }
    return false;
  }

  toggleSelectItem(item: T) {
    if (!this.selectItem(item)) {
      this.unselectItem(item);
    }
    if (!this.hasSelection()) {
      this.isSelectionMode = false;
    }
  }

  isItemSelected(item: T): boolean {
    return this.selectedItemsIds.indexOf(item["id"]) !== -1;
  }

  clearSelection() {
    if (!this.hasSelection()) {
      this.isSelectionMode = false;
      return;
    }
    this.selectedItemsIds.length = 0;
    this.isSelectionMode = false;
    this.itemSelect.emit({item: null, selectedItems: this.selectedItemsIds});
  }

  selectSingleItem(item: T) {
    this.selectedItemsIds.length = 0;
    this.isSelectionMode = false;
    const ret = this.selectItem(item);
    if (ret) {
      const element = document.getElementById(item["id"]);
      if (element) {
        element.scrollIntoView({block: "center", inline: "center", behavior: "smooth"});
      }
    }
    return ret;
  }

  isItemDisabled(item: T): boolean {
    return this.disabledItemsIds.indexOf(item["id"]) !== -1;
  }

  onItemPress($event: any, item: T) {
    if (!this.allowSelection) {
      return;
    }
    if (this.platform.width() >= 768) {
      return;
    }
    if (this.isSelectionMode) {
      return;
    }
    // this.soundService.playSound("drop");
    this.isSelectionMode = true;
    this.toggleSelectItem(item);
  }

  onItemClick($event: any, item: T) {
    if (!this.allowSelection) {
      if (!this.isItemDisabled(item)) {
        this.itemClick.emit(item);
      }
      return;
    }
    if (this.keypressUtilsService.isShiftKey($event)) {
      const clickedIdx = this.items.findIndex(x => x["id"] === item["id"]);
      const lastSelectedId = this.selectedItemsIds.length
        ? this.selectedItemsIds[this.selectedItemsIds.length - 1]
        : 0;
      const lastSelectedIdx = lastSelectedId
        ? this.items.findIndex(d => d["id"] === lastSelectedId)
        : 0;
      const step = clickedIdx > lastSelectedIdx ? 1 : -1;
      for (let idx = lastSelectedIdx; idx !== clickedIdx; idx += step) {
        this.selectItem(this.items[idx]);
      }
      this.selectItem(item);
      return;
    }
    if (this.keypressUtilsService.isCtrlKey($event)) {
      this.toggleSelectItem(item);
      return;
    }
    if (this.isSelectionMode) {
      this.toggleSelectItem(item);
      return;
    }
    if (this.selectedItemsIds.length > 1) {
      this.selectedItemsIds = [item["id"]];
      return;
    }
    this.itemClick.emit(item);
  }

  addItemToList(item: T): boolean {
    if (this.isItemFromList(item)) {
      return false;
    }
    this.items.push(item);
    this.pagination.recordCount++;
    this.computeRows(true);
    this.dataLoad.emit();
    return true;
  }

  updateItemFromList(item: T): boolean {
    const predicate = item["uiId"]
      ? (x: any) => x.uiId === item["uiId"]
      : (x: any) => x.id === item["id"];
    const isUpdated = this.arrayService.updateObject(this.items, item, predicate);
    if (isUpdated) {
      if (this.isItemSelected(item) && +item["id"] !== +item["uiId"]) {
        const idxByUiId = item["uiId"] ? this.selectedItemsIds.indexOf(+item["uiId"]) : -1;
        if (idxByUiId !== -1) {
          this.selectedItemsIds.splice(idxByUiId, 1);
        }
      }
      this.dataLoad.emit();
    }
    return isUpdated;
  }

  upsertItemFromList(item: T): boolean {
    if (!this.updateItemFromList(item)) {
      return this.addItemToList(item);
    }
    return true;
  }

  removeItemFromList(item: T): boolean {
    this.unselectItem(item);
    const predicate = item["uiId"]
      ? (x: any) => x.uiId === item["uiId"]
      : (x: any) => x.id === item["id"];
    const isRemoved = this.arrayService.removeItem(this.items, predicate);
    if (isRemoved) {
      this.pagination.recordCount--;
      this.computeRows(true);
      this.dataLoad.emit();
    }
    return isRemoved;
  }

  private disableItem(item: T): boolean {
    if (!this.isItemFromList(item)) {
      return false;
    }
    this.unselectItem(item);
    const idx = this.disabledItemsIds.indexOf(item["id"]);
    if (idx === -1) {
      this.disabledItemsIds.push(item["id"]);
      this.itemDisable.emit({item, disabledItems: this.disabledItemsIds});
      return true;
    }
    return false;
  }

  enableItem(item: T): boolean {
    if (!this.isItemFromList(item)) {
      return false;
    }
    const idx = this.disabledItemsIds.indexOf(item["id"]);
    if (idx === -1) {
      return false;
    }
    this.disabledItemsIds.splice(idx, 1);
    this.itemDisable.emit({item, disabledItems: this.disabledItemsIds});
    return true;
  }

  private handleOnItemAdd(evt: MqEvent<T>): boolean {
    const isUpdated = this.updateItemFromList(evt.payload);
    if (isUpdated) {
      return false;
    }
    if (evt.fromSocket && !this.forceRefreshOnSocket) {
      if (!this.needsReload) {
        this.needsReload = true;
        this.notifyNeedsReload.emit();
        return true;
      }
    } else {
      return this.addItemToList(evt.payload);
    }
    return false;
  }

  handleOnItemUpdate(evt: MqEvent<T>): boolean {
    const isUpdated = this.updateItemFromList(evt.payload);
    if (evt.fromSocket && !this.forceRefreshOnSocket) {
      if (!this.needsReload && !isUpdated) {
        this.needsReload = true;
        this.notifyNeedsReload.emit();
        return true;
      }
    }
    return isUpdated;
  }

  private handleOnItemUpsert(evt: MqEvent<T>): boolean {
    if (!this.handleOnItemUpdate(evt)) {
      return this.handleOnItemAdd(evt);
    }
    return true;
  }

  handleOnItemDelete(evt: MqEvent<T>): boolean {
    if (evt.fromSocket && !this.forceRefreshOnSocket) {
      setTimeout(() => {
        this.updateItemFromList(evt.payload);
        if (this.disableItem(evt.payload)) {
          if (!this.needsReload) {
            this.needsReload = true;
            this.notifyNeedsReload.emit();
          }
        }
      }, 500);
      return true;
    }
    return this.removeItemFromList(evt.payload);
  }

  toggleViewType() {
    this.viewType = this.viewType === "list" ? "grid" : "list";
    this.computeRows();
  }

  sizeChanged(width: number) {
    this.listWidth = width;
    this.computeRows();
    // this.cdkVirtualScrollViewport.checkViewportSize();
  }

  handleContentFilterChange($event: CustomEvent) {
    if (!this.contentFilterFn) {
      return;
    }
    this.contentFilterString = $event.detail.value.toString().toLowerCase();
    this.computeRows(true);
    this.selectSingleItem(this.items[0]);
  }

  doContentFilterBlur($event: KeyboardEvent) {
    ($event.target as any).blur();
    this.selectUsingNav("arrowDown");
  }

  async doContentFilterApply($event: KeyboardEvent) {
    await this.handleHotkey(Object.assign($event, {hotKey: "enter"}) as HotkeyEvent);
  }

  private async handleHotkey(e: HotkeyEvent) {
    if (this.allowSelection && e.hotKey === "meta.a") {
      this.selectedItemsIds = this.items
        // .filter(x => !this.isItemDisabled(x))
        .map(x => x["id"]);
      this.itemSelect.emit({item: null, selectedItems: this.selectedItemsIds});
    } else if (e.hotKey === "esc") {
      this.clearSelection();
    } else if (["arrowLeft", "arrowRight", "arrowUp", "arrowDown"].includes(e.hotKey)) {
      const selectionChanged = this.selectUsingNav(e.hotKey);
      if (!selectionChanged && this.allowContentFilter && e.hotKey === "arrowUp") {
        await this.filterInput.setFocus();
      }
    } else if (["enter", "space"].includes(e.hotKey)) {
      const item = this.getItemById(this.selectedItemsIds[0]);
      if (item) {
        this.itemClick.emit(item);
      }
    }
  }

  private selectUsingNav(hotKey: string): boolean {
    const direction = hotKey.replace(new RegExp("arrow", "g"), "").toLowerCase() as Direction;

    const selectedItemId = this.selectedItemsIds.length
      ? this.selectedItemsIds[this.selectedItemsIds.length - 1]
      : -1;
    if (selectedItemId === -1) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.selectSingleItem(this.items[i])) {
          return true;
        }
      }
      return false;
    }
    const selectedItem = this.getItemById(selectedItemId);
    const selectedItemIdx = selectedItem ? this.getItemIdx(selectedItem) : 0;

    const newItem: T = this.getNearSiblingByIdx(selectedItemIdx, direction);
    const newItemIdx = this.getItemIdx(newItem);
    if (newItem) {
      if (["right"].includes(direction)) {
        for (let i = newItemIdx; i < this.items.length; i++) {
          if (this.selectSingleItem(this.items[i])) {
            return true;
          }
        }
      } else if (["left"].includes(direction)) {
        for (let i = newItemIdx; i > -1; i--) {
          if (this.selectSingleItem(this.items[i])) {
            return true;
          }
        }
      } else if (["down"].includes(direction)) {
        for (let i = newItemIdx; i < this.items.length; i += this.cols) {
          if (this.selectSingleItem(this.items[i])) {
            return true;
          }
        }
      } else if (["up"].includes(direction)) {
        for (let i = newItemIdx; i > -1; i -= this.cols) {
          if (this.selectSingleItem(this.items[i])) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
