<ion-card class="w-100 h-100 m-0 overflow-hidden">
  @if (loadState?.isWorking) {
    <app-preloader class="overlay-absolute"></app-preloader>
  }
  <ion-card-content class="d-flex flex-column h-100 px-0">
    <div class="modal-header">
      <div class="modal-header-title">
        @if (title) {
          <div class="ion-card-title mb-0"
               [translate]="title"
               (click)="titleClick.emit()">
          </div>
        }
        @if (hasShortcut) {
          <ion-icon name="golf-outline" color="dark"
                    class="cursor-pointer flex-shrink-0 sqr-sm badge badge-outline badge-dark"
                    aria-hidden="true"
                    (click)="shortcut.emit()">
          </ion-icon>
        }
        @if (hasHelp) {
          <ion-icon name="help-outline" color="dark"
                    class="cursor-pointer flex-shrink-0 sqr-sm badge badge-outline badge-dark"
                    aria-hidden="true"
                    (click)="help.emit()">
          </ion-icon>
        }
        @if (hasClose) {
          <ion-icon name="close-outline" color="dark"
                    class="cursor-pointer flex-shrink-0 sqr-sm ml-2"
                    aria-hidden="true"
                    (click)="close.emit()">
          </ion-icon>
        }
      </div>
      <div class="w-100">
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
    <ion-content color="white" [scrollY]="false">
      <div appScrollAntiBlock
           [isDisabled]="isScrollAntiBlockDisabled"
           class="modal-content">
        <ng-content select="[content]"></ng-content>
      </div>
    </ion-content>
    @if (hasFooter) {
      <div class="modal-footer bg-white">
        <ng-content select="[footer]"></ng-content>
      </div>
    }
  </ion-card-content>
</ion-card>
